import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'
import ArticleCard from '../components/knowledgehub/ArticleCard'
import useWindowSize from '../functions/useWindowSize'
import { articleCardSizes, getBlocksFromArticles, lgWidth, mdWidth } from '../functions/ArticleUtils'

const START_LIMIT = 9;
const checkAll = (newArray) => {
    const allSectionIndex = newArray.findIndex(({ name }) => name === 'all')
    const selectedSection = newArray[allSectionIndex];
    const hasOther = newArray.filter((item) =>  (item.name !== 'all' && item.selected === true));
    if ((hasOther.length > 0 && newArray[allSectionIndex].selected === true) || (hasOther === 0 && newArray[allSectionIndex].selected === false)) {
        return [
            ...newArray.slice(0, allSectionIndex),
            {
                ...selectedSection,
                selected: !selectedSection.selected
            },
            ...newArray.slice(allSectionIndex + 1),
        ];
    }
    if (hasOther.length === newArray.length-1 && newArray[allSectionIndex].selected === false) {
        return newArray.map((item) => ({
                name: item.name,
                selected: !item.selected
            }
        ))
        
    }
    if (hasOther.length === 0 & newArray[allSectionIndex].selected === false) {
        return [
            ...newArray.slice(0, allSectionIndex),
            {
                ...selectedSection,
                selected: !selectedSection.selected
            },
            ...newArray.slice(allSectionIndex + 1),
        ];
    }
    return newArray

}


const KnowledgeHub = ({ data: { allContentfulKnowledgeHubArticles }, location }) => {
    const [limit, setLimit] = useState(START_LIMIT);
    const [showButton, setShowButton] = useState(true);
    const windowSize = useWindowSize()
    const columnCount = windowSize.width > lgWidth ? 3 : (windowSize.width > mdWidth ? 2 : 1)
    const uniqueKnowledgeHubSections = [
        ...new Set(
            allContentfulKnowledgeHubArticles.nodes.reduce((acc, { knowledge_hub_sections }) => (
                knowledge_hub_sections ? [...acc, knowledge_hub_sections?.[0].title] : acc
            ), ['all'])
        )
    ]

    const [selectedKnowledgeHubSections, setSelectedKnowledgeHubSections] = useState([
        ...uniqueKnowledgeHubSections.map((section) => ({name: section, selected: section === 'all' ? true : false}))
    ])

    let filteredArticles = [];

    if (selectedKnowledgeHubSections.find((item) => item.name === 'all').selected === true) {
        filteredArticles = allContentfulKnowledgeHubArticles.nodes.filter(({ knowledge_hub_sections }) =>  (
            knowledge_hub_sections !== null 
        ))
    }
    else {
        filteredArticles = allContentfulKnowledgeHubArticles.nodes.filter(({ knowledge_hub_sections }) => (
            knowledge_hub_sections !== null 
            &&
            (!knowledge_hub_sections
            || selectedKnowledgeHubSections
                .filter(({ selected }) => selected)
                .map(({ name }) => name)
                .includes(knowledge_hub_sections?.[0].title))
        ));
        
    }
    
   
    const articlesToBlocks = getBlocksFromArticles(filteredArticles)

    const limitedArticles = articlesToBlocks.slice(0, limit);

    const handleClick = () => {
        setLimit(limit + START_LIMIT);
        if(limit >= (articlesToBlocks.length-START_LIMIT)) {
            setShowButton(false);
        }
    }

    useEffect(() => {
        if(limit >= (articlesToBlocks.length)) {
            setShowButton(false);
        }
    }, [articlesToBlocks])

   
    const handleSelectedKnowledgeHubSectionsChange = (section) => () => {
        setSelectedKnowledgeHubSections((prevSelectedKnowledgeHubSections) => {
            setLimit(START_LIMIT);
            setShowButton(true);;
            if(section === 'all') {
                return [
                    ...uniqueKnowledgeHubSections.map((section) => ({name: section, selected: section === 'all' ? true : false}))
                ]
            }
            const selectedSectionIndex = prevSelectedKnowledgeHubSections.findIndex(({ name }) => name === section)
            const selectedSection = prevSelectedKnowledgeHubSections[selectedSectionIndex]  
            let newArray = [
                ...prevSelectedKnowledgeHubSections.slice(0, selectedSectionIndex),
                {
                    ...selectedSection,
                    selected: !selectedSection.selected
                },
                ...prevSelectedKnowledgeHubSections.slice(selectedSectionIndex + 1),
            ];
            newArray = checkAll(newArray);
            return newArray;
        })
    }

    return (
        <Layout activePage="/knowledge-hub" location={location} container={false}>
            <Header
                title="The Knowledge Hub | London Office Space Experts | Kontor"
                description="Keep up to date with industry news & insights, as well as company updates, with the Kontor knowledge hub. Curated to provide relevant & helpful updates to all."
            />
            <div className="flex flex-col items-center px-0 relative z-10">
                <h1 
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="text-primary lg:w-2/3 xl:w-3/5 text-center mx-auto mt-24 lg:mt-48 mb-16 leading-relaxed text-4xl"
                >
                    Everything you ever need to know about offices... ever
                </h1>
                <div className="flex items-center justify-center flex-wrap px-4 lg:px-32 -m-2 mb-6">
                    {uniqueKnowledgeHubSections.map((section) => (
                        <button
                            key={section}
                            className={`${selectedKnowledgeHubSections.find(({ name, selected }) => name === section && selected) ? 'bg-primary text-white' : 'bg-white text-primary'} border-2 border-primary rounded-md focus:outline-none transition-quick px-2 py-1 m-2`}
                            onClick={handleSelectedKnowledgeHubSectionsChange(section)}
                        >
                            <p className="text-xs uppercase text-center m-0">{section}</p>
                        </button>
                    ))}
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                    className="w-full mx-auto pt-4 lg:px-32 mb-48 flex flex-wrap -m-4"
                >
                    {limitedArticles.length > 0 && (limitedArticles.map((block) => (
                        <div
                            key={block?.id || block.map(({ id }) => id).join()}
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            className=" flex flex-col justify-between m-4"
                            style={{
                                height: '500px',
                                flex: `0 0 calc(100% / ${block?.size === articleCardSizes.LARGE ? 1.5 : columnCount} - 2rem)`
                            }}
                        >
                            {Array.isArray(block)
                                ? block.map((article) => (
                                    <ArticleCard key={article.id} article={article} size={articleCardSizes.SMALL} />
                                ))
                                : <ArticleCard article={block} size={block.size} />
                            }
                        </div>
                    )))}
                    {limitedArticles.length < 1 && (
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className=" flex flex-col justify-center m-4"
                    >
                        <p>Oops - it's not you, it's us!</p>
                        <br/>
                        <p>Try clicking on another tab to see more content</p>
                        </div>
                    )}
                    <div 
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500" 
                        className='flex justify-center w-full'
                    >
                       {limitedArticles.length > 0 && showButton && <button className='flex justify-center bg-secondary text-primary border-2 border-primary p-3 my-2 inline-block flex-wrap shadow-md hover:shadow-2xl transition-quick cursor-pointer mx-4' type='button' onClick={() => handleClick()}>Show More</button>}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

KnowledgeHub.propTypes = {
    data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
    query KnowledgeHubArticles {
        allContentfulKnowledgeHubArticles(sort: { fields: createdAt, order: DESC }) {
            nodes {
                id
                title
                slug
                content {
                    json
                }
                updatedAt
                createdAt
                knowledge_hub_sections {
                    title
                }
            }
        }
    }
`

export default KnowledgeHub
